import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Card, CardContent, Container, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { AuthStatus, useAuth } from './Auth';
import { RequestSnackbar, useRequest } from '../api/Request';
import { LoadingButton } from '@mui/lab';
import LoadingPage from '../loading/LoadingPage';
import { getConfig } from '../config';
import ArtistLogo from '../assets/ArtistLogo.svg';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { Email as EmailIcon, Lock, CheckCircle, Password, LockReset, Login} from '@mui/icons-material';

const googleSigninMessage =
    'Your email is not verified. Note that if you previously signed in with Google, you must continue to use that option. You will not be able to reset your password in that case.';

enum ForgotPasswordStep {
    Start = 'START',
    Confirm = 'CONFIRM',
    Success = 'SUCCESS',
}

const ForgotPasswordPage = () => {
    const auth = useAuth();

    const navigate = useNavigate();

    const [step, setStep] = useState(ForgotPasswordStep.Start);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState<string>();
    const request = useRequest();
    const config = getConfig();

    if (auth.status === AuthStatus.Loading) {
        return <LoadingPage />;
    }

    if (auth.status === AuthStatus.Authenticated) {
        return <Navigate to='/' />;
    }

    const onSubmit = () => {
        if (email.length === 0) {
            setEmailError('Email is required');
            return;
        }
        setEmailError(undefined);

        request.onStart();
        auth.forgotPassword(email)
            .then(() => {
                request.onSuccess();
                setStep(ForgotPasswordStep.Confirm);
            })
            .catch((err) => {
                request.onFailure(err);
                if (err.code === 'UserNotFoundException') {
                    setEmailError('Account with this email does not exist');
                } else if (err.code === 'NotAuthorizedException') {
                    setEmailError('Email is not verified');
                    request.onFailure({
                        message: googleSigninMessage,
                    });
                } else {
                    setEmailError(err.message);
                }
            });
    };

    const onCancel = () => {
        navigate('/signin');
    };

    return (
        <Container maxWidth='sm' sx={{ pt: 0, pb: 4, mb: '100px' }}>
            <Card
                elevation={6}
                sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                }}>
                <CardContent>
                    <Stack justifyContent='center' alignItems='center'>
                        <RequestSnackbar request={request} />
                        <img src={ArtistLogo} alt={`${config.artist.name}'s icon`}
                            style={{
                                width: '200px',
                                height: '200px',
                            }} />
                        <Typography variant='h4' textAlign='center' mb={4}>
                            {config.artist.name}
                        </Typography>
                        <Stack
                            direction='column'
                            justifyContent='center'
                            alignItems='center'
                            spacing={3}
                            paddingTop={1.5}
                        >
                            {step === ForgotPasswordStep.Start && (
                                <StartStep
                                    email={email}
                                    setEmail={setEmail}
                                    emailError={emailError}
                                    onSubmit={onSubmit}
                                    onCancel={onCancel}
                                    loading={request.isLoading()}
                                />
                            )}

                            {step === ForgotPasswordStep.Confirm && (
                                <ConfirmStep
                                    email={email}
                                    onSuccess={() => setStep(ForgotPasswordStep.Success)}
                                    onCancel={onCancel}
                                />
                            )}

                            {step === ForgotPasswordStep.Success && <SuccessStep />}
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    );
};

interface StartStepProps {
    email: string;
    setEmail: (email: string) => void;
    emailError?: string;
    onSubmit: () => void;
    onCancel: () => void;
    loading: boolean;
}

const StartStep: React.FC<StartStepProps> = ({
    email,
    setEmail,
    emailError,
    onSubmit,
    onCancel,
    loading,
}) => {
    return (
        <>
            <Typography
                variant='body1'
                component='div'
                gutterBottom
                textAlign='center'
            >                
                Enter your email, and we'll send you a code to reset your password.
            </Typography>

            <TextField
                fullWidth
                id='email'
                label='Email'
                variant='outlined'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={!!emailError}
                helperText={emailError}
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position='start'>
                                <EmailIcon color='primary' />
                            </InputAdornment>
                        ),
                    },
                }}
            />

            <LoadingButton
                type='submit'
                variant='contained'
                onClick={onSubmit}
                fullWidth
                startIcon={<MarkEmailUnreadIcon />}
                sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    padding: '12px 16px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                }}                 
                loading={loading}
            >
                Send Email
            </LoadingButton>

            <Button variant='text' sx={{ textTransform: 'none' }} onClick={onCancel}>
                Cancel
            </Button>
        </>
    );
};

interface ConfirmStepProps {
    email: string;
    onSuccess: () => void;
    onCancel: () => void;
}

const ConfirmStep: React.FC<ConfirmStepProps> = ({ email, onSuccess, onCancel }) => {
    const auth = useAuth();

    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState<string>();
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordError, setPasswordError] = useState<string>();

    const request = useRequest();

    const onConfirm = () => {
        let failed = false;

        if (code.length === 0) {
            setCodeError('Recovery code is required');
            failed = true;
        } else {
            setCodeError(undefined);
        }

        if (password.length < 8) {
            setPasswordError('Password must be at least 8 characters');
            failed = true;
        } else if (password !== passwordConfirm) {
            setPasswordError('Passwords do not match');
            failed = true;
        } else {
            setPasswordError(undefined);
        }

        if (failed) return;

        request.onStart();
        auth.forgotPasswordConfirm(email, code, password)
            .then(() => {
                request.onSuccess();
                onSuccess();
            })
            .catch((err) => {
                request.onFailure(err);
                console.error(err);
                if (err.code === 'CodeMismatchException') {
                    setCodeError('Incorrect recovery code');
                } else {
                    setCodeError(err.message);
                }
            });
    };

    return (
        <>
            <RequestSnackbar request={request} />

            <Typography
                variant='subtitle1'
                component='div'
                gutterBottom
                textAlign='center'
            >
                Email sent! Enter the code to reset your password.
            </Typography>

            <TextField
                fullWidth
                id='code'
                label='Recovery Code'
                variant='outlined'
                value={code}
                onChange={(event) => setCode(event.target.value)}
                error={!!codeError}
                helperText={codeError}
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position='start'>
                                <Password color='primary' />
                            </InputAdornment>
                        ),
                    },
                }}
            />

            <TextField
                fullWidth
                id='password'
                label='New Password'
                type='password'
                variant='outlined'
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                error={!!passwordError}
                helperText={passwordError}
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position='start'>
                                <Lock color='primary' />
                            </InputAdornment>
                        ),
                    },
                }}
            />

            <TextField
                fullWidth
                id='password-confirm'
                label='Confirm New Password'
                type='password'
                variant='outlined'
                value={passwordConfirm}
                onChange={(event) => setPasswordConfirm(event.target.value)}
                error={!!passwordError}
                helperText={passwordError}
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position='start'>
                                <CheckCircle color='primary' />
                            </InputAdornment>
                        ),
                    },
                }}
            />

            <LoadingButton
                type='submit'
                variant='contained'
                onClick={onConfirm}
                fullWidth
                startIcon={<LockReset />}
                sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    padding: '12px 16px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                }}
                loading={request.isLoading()}
            >
                Reset Password
            </LoadingButton>

            <Button variant='text' sx={{ textTransform: 'none' }} onClick={onCancel}>
                Cancel
            </Button>
        </>
    );
};

const SuccessStep = () => {
    const navigate = useNavigate();

    const onSignin = () => {
        navigate('/signin');
    };

    return (
        <>
            <Typography
                variant='subtitle1'
                component='div'
                gutterBottom
                textAlign='center'
            >
                You can now sign in using your new password.
            </Typography>

            <Button
                variant='contained'
                onClick={onSignin}
                fullWidth
                startIcon={<Login />}
                sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    padding: '12px 16px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                }}            >
                Sign In
            </Button>
        </>
    );
};

export default ForgotPasswordPage;
