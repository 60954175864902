import { useState } from 'react';
import { Button, Container, IconButton, Stack, TextField, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CommunityPostRequest } from '../../api/communityApi';
import { RequestSnackbar, useRequest } from '../../api/Request';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import { useApi } from '../../api/Api';
import { CommunityPost } from '../../database/community';


interface CommunityPostSubmissionFormProps {
    onSubmit: (post: CommunityPost) => void;
}

const CommunityPostSubmissionForm: React.FC<CommunityPostSubmissionFormProps> = ({
    onSubmit,
}) => {
    const [content, setPostContent] = useState('');
    // const [errors, setErrors] = useState<Record<string, string>>({});
    const [previewUrl, setPreviewUrl] = useState<string>();
    const [fileToUpload, setFileToUpload] = useState<File>();

    const api = useApi();
    const theme = useTheme();

    const createPostRequest = useRequest();
    const createCommunityPost = (content: string) => {
        createPostRequest.onStart();
        const containsMedia = fileToUpload !== undefined ? true : false
        const req: CommunityPostRequest = {
            content,
            containsMedia
        }
        api.createCommunityPost(req)
            .then((response) => {
                response.data.previewUrl = previewUrl
                onSubmit(response.data);
                setPreviewUrl('');
                setPostContent('');
                if (fileToUpload !== undefined) {
                    // Generate presigned URL
                    api.communityPostGeneratePresignedUrl(response.data, fileToUpload.type)
                        .then((resp) => {
                            // Upload media to S3
                            api.uploadMediaToS3({
                                presignedUrl: resp.data.presignedUrl,
                                mediaContent: fileToUpload,
                                contentType: fileToUpload.type,
                            })
                                .then(() => {
                                    // Message to user for successful upload if I want
                                })
                                .catch((err: unknown) => {
                                    console.error('Unable to upload media: ', err);
                                    createPostRequest.onFailure();
                                });
                        })
                        .catch((err: unknown) => {
                            console.error('Unable to upload media, signing error: ', err);
                            createPostRequest.onFailure();
                        });
                } 
                createPostRequest.onSuccess('Successfully saved post');
            })
            .catch((err) => {
                console.error('Create community post: ', err);
                createPostRequest.onFailure(err);
            });
    };

    const onChangePostPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (!file) return;
        // Clear previewUrl if previously uploaded a video
        if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
            setPreviewUrl('');
        }

        setFileToUpload(file)
        setPreviewUrl(URL.createObjectURL(file))
    };

    const handleSubmit = () => {
        const errors: Record<string, string> = {};
        // setErrors(errors);
        if (Object.entries(errors).length > 0) {
            return;
        }

        createCommunityPost(content);
    };

    return (
        <Container
            maxWidth='xl'
            sx={{
                mt: '50px',
            }}
        >
            <Stack spacing={2} alignItems='center' justifyContent='center'>
                <RequestSnackbar request={createPostRequest} />
                <Stack
                    spacing={2}
                    alignItems='center'
                    justifyContent='center'
                    width='100%'
                    maxWidth='600px'
                >
                    <TextField
                        label='Share with the community'
                        placeholder='Share with the community'
                        value={content}
                        onChange={(e) => setPostContent(e.target.value)}
                        multiline
                        minRows={1}
                        maxRows={7}
                        fullWidth={true}
                    />
                    {previewUrl && (
                        <Stack
                            overflow='auto'
                            maxHeight='400px'
                            style={{ borderRadius: '10px', position: 'relative' }}
                        >
                            <img
                                src={previewUrl}
                                style={{ maxHeight: `${600}px`, width: 'auto' }}
                                alt="Upload preview"
                            />
                            <IconButton
                                onClick={() => {
                                    setPreviewUrl('');
                                }}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    color: `${theme.palette.primary.main}`,
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                    )}
                    <Stack
                        direction='row'
                        width='100%'
                        maxWidth='600px'
                        justifyContent='space-between'
                    >
                        <Button
                            component='label'
                            variant='contained'
                            startIcon={<PhotoLibraryIcon />}
                            sx={{ marginRight: 'auto' }}
                        >
                            Upload
                            <input
                                type='file'
                                accept='image/*'
                                hidden
                                onChange={onChangePostPicture}
                            />
                        </Button>
                        <LoadingButton
                            variant='contained'
                            loading={createPostRequest.isLoading()}
                            onClick={handleSubmit}
                            endIcon={<SendIcon />}
                        >
                            Post
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Stack>
        </Container>
    );
};

export default CommunityPostSubmissionForm;
