import { Checkbox, FormControlLabel, IconButton, Modal, Stack, Typography, useTheme } from '@mui/material';
import { useApi } from '../api/Api';
import { useRequest } from '../api/Request';
import { Question } from '../database/theSource';
import { User } from '../database/user';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import Icon from '../components/Icon';
import PaymentInfoModal from './PaymentInfoModal';

interface QuestionSubmissionModalProps {
    isModalOpen: boolean;
    handleCloseModal: () => void;
    onSubmit: (question: Question) => void;
    user: User;
    questionContent: string;
    responseType: string;
    cost: number;
    isAcknowledged: boolean;
    setIsAcknowledged: (value: boolean) => void;
}

const QuestionSubmissionModal: React.FC<QuestionSubmissionModalProps> = ({
    isModalOpen,
    handleCloseModal,
    onSubmit,
    user,
    questionContent,
    responseType,
    cost,
    isAcknowledged,
    setIsAcknowledged
}) => {
    const createQuestionRequest = useRequest<Question>();
    const setUpFuturePaymentsRequest = useRequest();
    const api = useApi();
    const theme = useTheme();

    const createQuestion = (content: string, type: string) => {
        createQuestionRequest.onStart();
        api.createQuestion({
            content: content,
            responseType: type,
            isAcknowledged
        })
            .then((resp) => {
                onSubmit(resp.data);
                handleCloseModal();
                createQuestionRequest.onSuccess();
            })
            .catch((err: unknown) => {
                console.error('createQuestion error: ', err);
                createQuestionRequest.onFailure(err);
            });
    };

    const isPaymentInfoComplete = () => {
        return !(user.paymentInfo === null || user.paymentInfo?.customerId === "" || user.paymentInfo?.paymentMethodId === "")
    }

    const handleAcknowledgement = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAcknowledged(event.target.checked);
    };

    const setUpFuturePayment = (content: string, type: string) => {
        setUpFuturePaymentsRequest.onStart();
        api.setUpFuturePayment({
            content: content,
            responseType: type,
            isAcknowledged,
        })
            .then((resp) => {
                window.location.href = resp.data.url;
            })
            .catch((err: unknown) => {
                console.error('setUpFuturePayment: ', err);
                setUpFuturePaymentsRequest.onFailure(err);
            });
    };

    return (
        <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Stack
                width={{ xs: '90%', md: '50%' }}
                sx={{
                    borderRadius: '16px',
                    borderColor: `${theme.palette.primary.main}`,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    maxHeight: '80%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: `${theme.palette.background.default}`,
                    padding: 2,
                }}
            >
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='h5' fontWeight='bold'>
                        Question Confirmation
                    </Typography>
                    <IconButton
                        onClick={handleCloseModal}
                        sx={{
                            marginLeft: 'auto',
                            color: `${theme.palette.secondary.main}`,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <Stack padding={2} spacing={2}>
                    <Stack>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <Typography variant='h6' fontWeight='bold'>
                                Your Question
                            </Typography>
                            <Icon name='question' color='primary' />
                        </Stack>
                        <Typography variant='h6' color='primary'>
                            {questionContent}
                        </Typography>
                    </Stack>
                    <Stack>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <Typography variant='h6' fontWeight='bold'>
                                Response Type
                            </Typography>
                            <Icon name='responseTypes' color='primary' />
                        </Stack>
                        <Typography variant='h6' color='primary'>
                            {responseType.charAt(0).toUpperCase() +
                                responseType.slice(1).toLowerCase()}
                        </Typography>
                    </Stack>
                    <Stack>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <Typography variant='h6' fontWeight='bold'>
                                Cost
                            </Typography>
                            <Icon name='cost' color='primary' />
                        </Stack>
                        <Typography variant='h6' color='primary'>
                            ${cost}
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isAcknowledged}
                                    onChange={handleAcknowledgement}
                                    sx={{
                                        color: theme.palette.secondary.main
                                    }}
                                />
                            }
                            label="I acknowledge I will not be charged unless my question is answered within 30 days." />
                    </Stack>
                    <PaymentInfoModal user={user} choosePaymentMethod={() => setUpFuturePayment(questionContent, responseType)} />
                </Stack>
                <LoadingButton
                    variant='contained'
                    onClick={() => createQuestion(questionContent, responseType)}
                    loading={createQuestionRequest.isLoading()}
                    sx={{
                        opacity: isPaymentInfoComplete() && isAcknowledged ? 1 : 0.4,
                        textTransform: 'none',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        padding: '12px 16px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                    }}
                    disabled={!isPaymentInfoComplete() || !isAcknowledged}
                >
                    Buy Question
                </LoadingButton>
            </Stack>
        </Modal>
    );
};

export default QuestionSubmissionModal;
