import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Question } from '../database/theSource';
import Avatar from '../profile/info/Avatar';
import { QuestionStatus } from './constants/QuestionStatus';
import QuestionStatusChip from './chips/QuestionStatusChip';
import { ResponseType } from './constants/ResponseType';
import AnswerPromptCard from './AnswerPromptCard';
import QuestionCostChip from './chips/QuestionCostChip';
import { useAuth } from '../auth/Auth';
import { useRequest } from '../api/Request';
import { useApi } from '../api/Api';
import QuestionTimerChip from './chips/QuestionExpirationTimerChip';
import { getConfig } from '../config';

const theSourceMediaBucket = getConfig().media.theSourceMediaBucket;

interface QuestionCardProps {
    question: Question;
    onDelete: (question: Question) => void;
    onSave: (question: Question) => void;
}

const QuestionCard: React.FC<QuestionCardProps> = ({ question, onDelete, onSave }) => {
    const theme = useTheme();
    const auth = useAuth();
    const user = auth.user!
    const deleteQuestionRequest = useRequest();
    const api = useApi();

    const handleDelete = (question: Question) => {
        deleteQuestionRequest.onStart();
        api.deleteQuestion(question)
            .then((response) => {
                onDelete(response.data);
                deleteQuestionRequest.onSuccess('Successfully deleted question');
            })
            .catch((err) => {
                console.error('Delete question error: ', err);
                deleteQuestionRequest.onFailure(err);
            });
    };

    const renderAnswerContent = () => {
        if (question.status !== QuestionStatus.Answered) {
            return null
        }
        switch (question.responseType) {
            case ResponseType.Text: {
                return (
                    <Typography>
                        {question.answer?.content}
                    </Typography>
                )
            }
            case ResponseType.Video: {
                return (
                        <video controls preload="metadata" controlsList="nodownload" style={{ width: '100%', height: 'auto', borderRadius: '16px' }}>
                            <source src={question.previewUrl ? question.previewUrl : `${theSourceMediaBucket}/${question.owner}/${question.createdAt}`} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                )
            }
            default: {
                return (
                    <Typography>
                        Could not obtain the answer to this question
                    </Typography>
                )
            }
        }
    }

    return (
        <>
            <Box
                sx={{
                    borderRadius: '16px',
                    borderColor: `${theme.palette.primary.main}`,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                }}
            >
                <Stack direction="row" padding="10px" alignItems='flex-start' justifyContent="space-between">

                    <Stack direction='row' padding='10px' alignItems='center'>
                        <Avatar
                            username={question.owner}
                            displayName={question.ownerDisplayName}
                            size={50}
                        />
                        <Stack paddingLeft='5px'>
                            <Typography variant='body2' fontWeight='bold' marginBottom='0px'>
                                {question.ownerDisplayName}
                            </Typography>
                            <Typography variant='caption'>
                                {new Date(question.createdAt).toLocaleDateString()}
                                {' @ '}
                                {new Date(question.createdAt).toLocaleTimeString([], {
                                    hour: 'numeric',
                                    minute: '2-digit',
                                    hour12: true,
                                })}{' '}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack spacing={1}>
                        {user.isArtist && (
                            <>
                                {question.status === QuestionStatus.Pending && (
                                    <>
                                        <IconButton
                                            onClick={() => handleDelete(question)}
                                            style={{
                                                position: 'relative',
                                                top: 0,
                                                right: 0,
                                                marginLeft: 'auto',
                                                color: `${theme.palette.primary.main}`,
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                        <QuestionTimerChip createdAt={question.createdAt} />
                                    </>
                                )}
                            </>
                        )}
                        {(user.isArtist || user.username === question.owner) && (
                            <QuestionCostChip cost={question.cost} user={user}/>
                        )}
                        <QuestionStatusChip status={question.status as QuestionStatus} />

                    </Stack>
                </Stack>
                <Box sx={{ padding: '20px', paddingTop: '0px' }}>
                    <Stack spacing={3}>
                        <Typography
                            sx={{
                                overflowWrap: 'break-word',
                            }}
                        >
                            {question.content}
                        </Typography>
                        {renderAnswerContent()}

                        <AnswerPromptCard question={question} onSave={onSave} />
                    </Stack>
                </Box>
            </Box>
        </>
    );
};

export default QuestionCard;
