import { Box, Button, Container, Grid2, Stack, Tab, Tabs, useMediaQuery } from '@mui/material';
import { AuthStatus, useAuth } from '../auth/Auth';
import { useMemo, useState } from 'react';
import LoadingPage from '../loading/LoadingPage';
import { ListQuestionsResponse } from '../api/theSourceApi';
import { Question } from '../database/theSource';
import QuestionSubmissionForm from './QuestionSubmissionForm';
import { TabContext, TabPanel } from '@mui/lab';
import SocialBar from '../socials/SocialBar';
import TheSourceTab, { TabValue } from './TheSourceTab';
import { TheSourceFilters, useFilters } from './TheSourceFilters';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { QuestionStatus } from './constants/QuestionStatus';
import { ResponseType } from './constants/ResponseType';
import LegalBar from '../components/LegalBar';

const TheSourcePage = () => {
    const auth = useAuth();
    const user = auth.user!
    const [data, setData] = useState<ListQuestionsResponse>();
    const [value, setValue] = useState<TabValue>('recent');
    const [showFilters, setShowFilters] = useState(true);
    const isMobile = useMediaQuery('(max-width:800px)')

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const onCreateQuestion = (question: Question) => {
        setValue('recent')
        const newData = data?.questions || [];
        setData({
            questions: [question, ...newData],
            lastEvaluatedKey: data?.lastEvaluatedKey || '',
        });
    };

    const filters = useFilters();

    const filteredQuestions = useMemo(() => {
        if (!data?.questions) return [];

        return data.questions.filter((question) => {
            const matchesResponseType = filters.responseTypeFilters.includes(question.responseType as ResponseType);
            const matchesStatus = filters.statusFilters.includes(question.status as QuestionStatus);
            return matchesResponseType && matchesStatus;
        });
    }, [data, filters]);

    if (auth.status === AuthStatus.Loading) {
        return <LoadingPage />;
    }

    return (
        <Container maxWidth={false} sx={{ pt: 6, mb: isMobile ? '100px' : 0 }}>
            <Stack spacing={4}>
                <QuestionSubmissionForm onSubmit={(e) => onCreateQuestion(e)} />
                <Grid2 container spacing={2}>
                    <Grid2 size={{ xs: 12, md: 2.5, xl: 2 }}>
                        <Button
                            onClick={toggleFilters}
                            startIcon={showFilters ? <VisibilityOff /> : <Visibility />}
                            variant='outlined'
                            sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                        >
                            {showFilters ? 'Hide Filters' : 'Show Filters'}
                        </Button>
                        {showFilters && <TheSourceFilters filters={filters} tabValue={value} />}
                    </Grid2>
                    <Grid2
                        size={{
                            xs: 12,
                            md: showFilters ? 9.5 : 12,
                            xl: showFilters ? 10 : 12,
                        }}
                    >
                        <TabContext value={value}>
                            <Box
                                sx={{
                                    width: '100%',
                                    typography: 'body1',
                                    mt: 5,
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={(_, t) => setValue(t)}
                                    textColor='inherit'
                                    variant='scrollable'
                                >
                                    {user.isArtist && (
                                        <Tab label={QuestionStatus.Pending} value={QuestionStatus.Pending} />
                                    )}
                                    <Tab label='Recent' value='recent' />
                                    <Tab label={QuestionStatus.Answered} value={QuestionStatus.Answered} />
                                    <Tab label='Videos' value={ResponseType.Video} />
                                    <Tab label='Texts' value={ResponseType.Text} />
                                    <Tab label='My Questions' value='my' />
                                </Tabs>
                            </Box>
                            <TabPanel value={QuestionStatus.Pending} sx={{ px: { xs: 0, sm: 3 } }}>
                                <TheSourceTab
                                    query={value}
                                    data={{ questions: filteredQuestions, lastEvaluatedKey: data?.lastEvaluatedKey }}
                                    setData={setData}
                                />
                            </TabPanel>
                            <TabPanel value='recent' sx={{ px: { xs: 0, sm: 3 } }}>
                                <TheSourceTab
                                    query={value}
                                    data={{ questions: filteredQuestions, lastEvaluatedKey: data?.lastEvaluatedKey }}
                                    setData={setData} />
                            </TabPanel>
                            <TabPanel value={QuestionStatus.Answered} sx={{ px: { xs: 0, sm: 3 } }}>
                                <TheSourceTab
                                    query={value}
                                    data={{ questions: filteredQuestions, lastEvaluatedKey: data?.lastEvaluatedKey }}
                                    setData={setData} />
                            </TabPanel>
                            <TabPanel value='my' sx={{ px: { xs: 0, sm: 3 } }}>
                                <TheSourceTab
                                    query={value}
                                    data={{ questions: filteredQuestions, lastEvaluatedKey: data?.lastEvaluatedKey }}
                                    setData={setData} />
                            </TabPanel>
                            <TabPanel value={ResponseType.Text} sx={{ px: { xs: 0, sm: 3 } }}>
                                <TheSourceTab
                                    query={value}
                                    data={{ questions: filteredQuestions, lastEvaluatedKey: data?.lastEvaluatedKey }}
                                    setData={setData} />
                            </TabPanel>
                            <TabPanel value={ResponseType.Video} sx={{ px: { xs: 0, sm: 3 } }}>
                                <TheSourceTab
                                    query={value}
                                    data={{ questions: filteredQuestions, lastEvaluatedKey: data?.lastEvaluatedKey }}
                                    setData={setData} />
                            </TabPanel>
                        </TabContext>
                    </Grid2>
                </Grid2>
            </Stack>
            <SocialBar />
            <LegalBar />
        </Container >
    );
};

export default TheSourcePage;
