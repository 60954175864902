import { Box, Button, Container, Stack, Typography } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowsProp,
} from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { AuthStatus, useAuth } from '../auth/Auth';
import { useEffect, useState } from 'react';
import { useApi } from '../api/Api';
import { RequestSnackbar, useRequest } from '../api/Request';
import { BandsintownEvent } from '../database/events';
import BandsInTownImage from '../assets/BandsInTown.svg';
import SocialBar from '../socials/SocialBar';
import LoadingPage from '../loading/LoadingPage';
import { useTheme } from '@mui/material/styles';
import { getConfig } from '../config';

const EventsPage = () => {
    const auth = useAuth();
    const api = useApi();
    const request = useRequest<BandsintownEvent[]>();
    const theme = useTheme();
    const [rows, setRows] = useState<GridRowsProp>([]);
    const config = getConfig();

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Date',
            minWidth: 175,
            valueFormatter: (value: string) => {
                const formattedDate = new Date(value).toLocaleDateString();
                const formattedTime = new Date(value).toLocaleTimeString([], {
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true,
                });
                const formattedDateTime = `${formattedDate} @ ${formattedTime}`;
                return formattedDateTime;
            },
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'venueName',
            headerName: 'Venue',
            minWidth: 175,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'venueLocation',
            headerName: 'Location',
            minWidth: 200,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'ticketLink',
            headerName: 'Ticket Link',
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, string>) => {
                return (
                    <Button
                        variant='contained'
                        onClick={() => {
                            window.open(params.value, '_blank');
                        }}
                    >
                        Tickets
                    </Button>
                );
            },
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'rsvpLink',
            headerName: 'RSVP Link',
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, string>) => {
                return (
                    <Button
                        variant='contained'
                        onClick={() => {
                            const rsvpUrl = `https://bandsintown.com/artist-rsvp/2619661?event_id=${params.value}`;
                            window.open(rsvpUrl, '_blank');
                        }}
                    >
                        RSVP
                    </Button>
                );
            },
            headerAlign: 'center',
            align: 'center',
        },
    ];

    useEffect(() => {
        if (!request.isSent()) {
            request.onStart();
            api.getEvents()
                .then((response) => {
                    const newRows = response.data.map((bandsInTownEvent, i) => ({
                        id: bandsInTownEvent.datetime,
                        venueName: bandsInTownEvent.venue.name,
                        venueLocation: bandsInTownEvent.venue.location,
                        rsvpLink: bandsInTownEvent.id,
                        ticketLink: bandsInTownEvent.offers.find(
                            (offer) => offer.type === 'Tickets',
                        )?.url,
                    }));
                    setRows(newRows);
                    request.onSuccess(response.data);
                })
                .catch((err) => {
                    console.error(err);
                    request.onFailure(err);
                });
        }
    }, [request, api]);

    if (auth.status === AuthStatus.Loading) {
        return <LoadingPage />;
    }

    if (!request.isSent() || request.isLoading()) {
        return <LoadingPage />;
    }

    if (!request.data) {
        return (
            <Stack>
                <RequestSnackbar request={request} />
            </Stack>
        );
    }

    if (request.data.length === 0) {
        return (
            <Container maxWidth='md' sx={{ pt: 6, pb: 4 }}>
                <Stack spacing={2} alignItems='center' mb={8}>
                    <Typography variant='h4'>No upcoming shows</Typography>
                    <Typography variant='h6' sx={{ textAlign: 'center'}}>
                        Get updates on new shows, music, and more.
                    </Typography>
                    <Button
                        variant='contained'
                        component={Link}
                        to={`https://bandsintown.com/artist-subscribe/${config.artist.bandsInTownId}`}
                        target='_blank'
                        rel='noopener noreferrer'
                        startIcon={<img src={BandsInTownImage} alt='Icon' />}
                    >
                        Follow
                    </Button>
                </Stack>
                <SocialBar />
            </Container>
        );
    }

    return (
        <Container maxWidth='md' sx={{ pt: 6, pb: 4 }}>
            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <DataGrid
                    rows={rows}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    hideFooter={true}
                    sx={{
                        '&.MuiDataGrid-root': {
                            bgcolor: `${theme.palette.secondary.light}`,
                        },
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: `${theme.palette.primary.main}`,
                        },
                        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                            py: '8px',
                        },
                        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                            py: '15px',
                        },
                        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                            py: '22px',
                        },
                    }}
                />
            </Box>
            <Stack
                spacing={2}
                justifyContent='center'
                alignItems='center'
                sx={{ mt: 8, mb: 8 }}
            >
                <Typography variant='body1' align='center'>
                    Get notified when new events are announced in your area.
                </Typography>
                <Button
                    variant='contained'
                    component={Link}
                    to={`https://bandsintown.com/artist-subscribe/${config.artist.bandsInTownId}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    startIcon={<img src={BandsInTownImage} alt='Icon' />}
                    sx={{
                        width: '150px',
                    }}
                >
                    Follow
                </Button>
            </Stack>
            <SocialBar />
        </Container>
    );
};

export default EventsPage;
