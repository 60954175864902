import { Divider, Stack, Typography, useMediaQuery } from "@mui/material";

const LegalBar = () => {
    const isMobile = useMediaQuery('(max-width:800px)')

    return (
        <Stack
            alignItems='center'
            direction='row'
            justifyContent={isMobile ? 'center' : 'flex-end'}
            padding={3}
            spacing={2}
            divider={<Divider orientation="vertical" flexItem />}
            useFlexGap
            sx={{ flexWrap: 'wrap' }}
        >
            <Typography>
                Privacy Policy
            </Typography>
            <Typography>
                Terms & Conditions
            </Typography>
            <Stack alignItems='center'>
                <Typography>
                    {'\u00A9'} 2024 Dainger Zone LLC
                </Typography>
                <Typography>
                    All rights reserved
                </Typography>
            </Stack>

        </Stack>
    )
}

export default LegalBar;