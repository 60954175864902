import { Link, Stack, Typography } from '@mui/material';
import { useApi } from '../api/Api';
import { useRequest } from '../api/Request';
import { User } from '../database/user';
import { useEffect } from 'react';
import { PaymentMethod, PaymentMethodType } from '../database/payment';
import { Payment } from '@mui/icons-material'

interface PaymentInfoModalProps {
    user: User;
    choosePaymentMethod: () => void;
}

const PaymentInfoModal: React.FC<PaymentInfoModalProps> = ({
    user,
    choosePaymentMethod,
}) => {
    const api = useApi();
    const getUserPaymentInfoRequest = useRequest<PaymentMethod>();

    useEffect(() => {
        if (!getUserPaymentInfoRequest.isSent()) {
            getUserPaymentInfoRequest.onStart();
            api.getUserPaymentInfo()
                .then((response) => {
                    console.log("Payment info: ", response)
                    getUserPaymentInfoRequest.onSuccess(response.data);
                })
                .catch((err) => {
                    console.error(err);
                    getUserPaymentInfoRequest.onFailure(err);
                });
        }
    }, [getUserPaymentInfoRequest, api, user.paymentInfo]);

    const paymentInfo = getUserPaymentInfoRequest.data;

    const getPaymentDetails = (paymentInfo: PaymentMethod) => {
        switch (paymentInfo?.type) {
            case PaymentMethodType.Card:
                return `${paymentInfo.card.display_brand
                    .replace(/_/g, ' ')
                    .toLowerCase()
                    .replace(/\b\w/g, char => char.toUpperCase())} ending in ${paymentInfo.card.last4}`;
            case PaymentMethodType.Link:
                return `Paying through ${paymentInfo.type
                    .toLowerCase()
                    .replace(/\b\w/g, char => char.toUpperCase())}`;
            default:
                return null;
        }
    };

    const paymentDetails = paymentInfo ? getPaymentDetails(paymentInfo) : null;

    return (
        <Stack>
            <Stack direction='row' spacing={1}>
                {paymentDetails ? <Payment color='secondary' /> : <></>}
                <Typography>{paymentDetails}</Typography>
            </Stack>
            <Link
                onClick={choosePaymentMethod}
                sx={{
                    cursor: 'pointer', // This changes the cursor to a pointer on hover
                }}
            >
                {paymentDetails !== null ? "Choose different payment method" : "Choose payment method"}
            </Link>
        </Stack>
    );
};

export default PaymentInfoModal;
