import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Stack,
    useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthStatus, useAuth } from '../auth/Auth';
import LoadingPage from '../loading/LoadingPage';
import Avatar from './info/Avatar';
import CreatedAtChip from './info/CreatedAtChip';
import TitleAndDescription from './info/TitleAndDescription';
import DisplayName from './info/DisplayName';
import { useApi } from '../api/Api';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { RequestSnackbar, useRequest } from '../api/Request';
import { useState } from 'react';

const ProfilePage = () => {
    const auth = useAuth();
    const api = useApi();
    const navigate = useNavigate();
    const deleteUserRequest = useRequest();
    const theme = useTheme();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const user = auth.user;

    if (auth.status === AuthStatus.Loading) {
        return <LoadingPage />;
    }

    const handleDelete = () => {
        deleteUserRequest.onStart();
        api.deleteUser()
            .then(() => {
                deleteUserRequest.onSuccess('Successfully deleted post');
                auth.deleteUser();
                navigate('/', { replace: true });
            })
            .catch((err) => {
                console.error('Delete community post: ', err);
                deleteUserRequest.onFailure(err);
            });
    };

    return (
        <Container maxWidth='md' sx={{ pt: 6, mb: '100px' }}>
            <Dialog
                open={showDeleteDialog}
                onClose={
                    deleteUserRequest.isLoading()
                        ? undefined
                        : () => setShowDeleteDialog(false)
                }
            >
                <RequestSnackbar request={deleteUserRequest} />
                <DialogTitle
                    sx={{
                        color: `${theme.palette.secondary.main}`,
                    }}
                >
                    Delete this user?
                    <IconButton
                        aria-label='close'
                        onClick={() => setShowDeleteDialog(false)}
                        sx={{
                            position: 'absolute',
                            right: 10,
                            top: 8,
                            color: `${theme.palette.secondary.main}`,
                        }}
                        disabled={deleteUserRequest.isLoading()}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        sx={{
                            color: `${theme.palette.secondary.main}`,
                        }}
                    >
                        Are you sure you want to delete this user? You can't undo this
                        action.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        onClick={() => handleDelete()}
                        loading={deleteUserRequest.isLoading()}
                    >
                        Delete User
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Stack spacing={4}>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    flexWrap='wrap'
                    rowGap={2}
                >
                    <Stack
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        flexWrap='wrap'
                        rowGap={1}
                    >
                        <Avatar user={user} size={150} />
                        <Stack spacing={2} alignItems='center'>
                            <DisplayName displayName={user!.displayName} />

                            <CreatedAtChip createdAt={user?.createdAt} />
                        </Stack>
                    </Stack>
                    <Stack direction='row' spacing={2}>
                        <Button
                            id='edit-profile-button'
                            variant='contained'
                            onClick={() => navigate('/profile/edit')}
                        >
                            Edit Profile
                        </Button>
                        <Button
                            id='delete-profile-Button'
                            variant='contained'
                            onClick={() => setShowDeleteDialog(true)}
                        >
                            Delete Profile
                        </Button>
                    </Stack>
                </Stack>
                <TitleAndDescription description={user?.bio} />
                {user && user?.isArtist && user.artistInfo && (
                    <>
                        <TitleAndDescription title='Fun Fact' description={user.artistInfo.funFact} />
                        <TitleAndDescription title='Track ID' description={user.artistInfo.musicId} />
                        <TitleAndDescription
                            title='YouTube Video ID'
                            description={user.artistInfo.videoId} />
                        <TitleAndDescription
                            title='The Source Text Response Cost'
                            description={`$${user.artistInfo.costTextResponseType}`} />
                        <TitleAndDescription
                            title='The Source Video Response Cost'
                            description={`$${user.artistInfo.costVideoResponseType}`} />
                    </>
                )}
                <Button
                    onClick={() =>
                        auth.signout()}
                    startIcon={<ExitToAppIcon color='error' />}
                    sx={{
                        alignSelf: 'start',
                        minWidth: 'auto',
                    }}
                    variant='outlined'
                    color='error'
                >
                    Sign Out
                </Button>
            </Stack>
        </Container>
    );
};

export default ProfilePage;
