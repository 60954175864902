export interface Config {
    auth: {
        region: 'us-east-1';
        userPoolId: string;
        userPoolWebClientId: string;
        oauth: {
            domain: string;
            scope: string[];
            redirectSignIn: string;
            redirectSignOut: string;
            responseType: 'code';
        };
    };
    api: {
        baseUrl: string;
    };
    media: {
        profileImagesBucket: string;
        communityImagesBucket: string;
        theSourceMediaBucket: string;
    };
    artist: {
        bandsInTownId: string;
        name: string;
        socialsName: string;
        spotifyId: string;
        youTubeId: string;
    };
}

const config: Record<string, Config> = {
    local: {
        auth: {
            region: 'us-east-1',
            userPoolId: 'us-east-1_aY3gOvyKA',
            userPoolWebClientId: '77oi6sitnsdtdolp80tm7c6sam',
            oauth: {
                domain: 'authlocal.kembexwrld.com',
                scope: ['profile', 'email', 'openid'],
                redirectSignIn: 'http://localhost:3000',
                redirectSignOut: 'http://localhost:3000',
                responseType: 'code',
            },
        },
        api: {
            baseUrl: 'https://o8b8x4w732.execute-api.us-east-1.amazonaws.com',
        },
        media: {
            profileImagesBucket:
                'https://d12v4qex3fjfxf.cloudfront.net',
            communityImagesBucket:
                'https://d3hfleq7alpdv3.cloudfront.net',
            theSourceMediaBucket:
                'https://d2l2jrp9g53qxx.cloudfront.net',
        },
        artist: {
            bandsInTownId: '1600064-kembe-x',
            name: 'Kembe X',
            socialsName: 'kembe_x',
            spotifyId: '4uc4iep3LqgSj8qN4kp7qD',
            youTubeId: 'UCA4ktxPuT05iVIEcveeib1Q',
        },
    },
    dev: {
        auth: {
            region: 'us-east-1',
            userPoolId: 'us-east-1_Q645YYtK3',
            userPoolWebClientId: '4gcn7u04lefnrb5rfj0siqv352',
            oauth: {
                domain: 'authdev.kembexwrld.com',
                scope: ['profile', 'email', 'openid'],
                redirectSignIn: 'https://dev.kembexwrld.com',
                redirectSignOut: 'https://dev.kembexwrld.com',
                responseType: 'code',
            },
        },
        api: {
            baseUrl: 'https://g19vu7a1v2.execute-api.us-east-1.amazonaws.com',
        },
        media: {
            profileImagesBucket:
                'https://d1vmkul3d6pwsf.cloudfront.net',
            communityImagesBucket:
                'https://d2di8a377ifqnj.cloudfront.net',
            theSourceMediaBucket:
                'https://d29mf51wszmgfx.cloudfront.net',
        },
        artist: {
            bandsInTownId: '1600064-kembe-x',
            name: 'Kembe X',
            socialsName: 'kembe_x',
            spotifyId: '4uc4iep3LqgSj8qN4kp7qD',
            youTubeId: 'UCA4ktxPuT05iVIEcveeib1Q',
        },
    },

    prod: {
        auth: {
            region: 'us-east-1',
            userPoolId: 'us-east-1_yfYneD0tY',
            userPoolWebClientId: '238qk61paobhggqueept0n4u3g',
            oauth: {
                domain: 'auth.kembexwrld.com',
                scope: ['profile', 'email', 'openid'],
                redirectSignIn: 'https://www.kembexwrld.com',
                redirectSignOut: 'https://www.kembexwrld.com',
                responseType: 'code',
            },
        },
        api: {
            baseUrl: 'https://6y2tvphu30.execute-api.us-east-1.amazonaws.com',
        },
        media: {
            profileImagesBucket:
                'https://d37kxvw4x3cntw.cloudfront.net',
            communityImagesBucket:
                'https://d1nhgq1y3vzme9.cloudfront.net',
            theSourceMediaBucket:
                'https://d1kzw8ex2aq8rw.cloudfront.net',
        },
        artist: {
            bandsInTownId: '1600064-kembe-x',
            name: 'Kembe X',
            socialsName: 'kembe_x',
            spotifyId: '4uc4iep3LqgSj8qN4kp7qD',
            youTubeId: 'UCA4ktxPuT05iVIEcveeib1Q',
        },
    },
};

export function getConfig(): Config {
    return config[process.env.REACT_APP_ENV || 'local'];
}
