import { Button, Card, CardContent, Container, Divider, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import { AccountCircle, Email as EmailIcon, Lock as LockIcon } from '@mui/icons-material';
import { AuthStatus, useAuth } from './Auth';
import { useState } from 'react';
import { RequestSnackbar, useRequest } from '../api/Request';
import { LoadingButton } from '@mui/lab';
import GoogleIcon from '@mui/icons-material/Google';
import LoadingPage from '../loading/LoadingPage';
import { getConfig } from '../config';
import ArtistLogo from '../assets/ArtistLogo.svg';

const SignUpPage = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<Record<string, string>>({});
    const request = useRequest();
    const config = getConfig();

    if (auth.status === AuthStatus.Loading) {
        return <LoadingPage />;
    }

    if (auth.status === AuthStatus.Authenticated) {
        return <Navigate to='/' />;
    }

    const onGoogleSignIn = () => {
        auth.socialSignin('Google');
    };

    // const onFacebookSignIn = () => {
    //     auth.socialSignin('Facebook');
    // };

    const onSignin = () => {
        const errors: Record<string, string> = {};
        if (name.trim().length === 0) {
            errors.name = 'Name is required';
        }
        if (email.trim().length === 0) {
            errors.email = 'Email is required';
        }
        if (password.length === 0) {
            errors.password = 'Password is required';
        }

        setErrors(errors);
        if (Object.values(errors).length > 0) {
            return;
        }

        request.onStart();
        auth.signup(name.trim(), email.trim(), password)
            .then((result) => {
                navigate('/verify-email', {
                    state: {
                        username: result.user.username,
                        name: name.trim(),
                        email: email.trim(),
                        password,
                    },
                });
            })
            .catch((err) => {
                console.error(err);
                request.onFailure(err);
            });
    };

    return (
        <Container maxWidth='sm' sx={{ pt: 0, pb: 4, mb: '100px' }}>
            <Card
                elevation={6}
                sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                }}>
                <CardContent sx={{ pt: 0 }}>
                    <Stack justifyContent='center' alignItems='center'>
                        <RequestSnackbar request={request} />

                        <img src={ArtistLogo} alt={`${config.artist.name}'s icon`}
                            style={{
                                width: '200px',
                                height: '200px',
                            }} />
                        <Typography variant='h4' textAlign='center' mb={4}>
                            {config.artist.name}
                        </Typography>
                        <Stack width={{ xs: 1, sm: 0.85 }} spacing={3} alignItems='center'>
                            <Button
                                variant='contained'
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '18px',
                                    padding: '12px 16px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                                }}
                                onClick={onGoogleSignIn}
                                startIcon={<GoogleIcon />}
                            >
                                Sign up with Google
                            </Button>
                            <Divider sx={{ width: 1 }}>Or</Divider>
                            <TextField
                                fullWidth
                                id='name'
                                label='Name'
                                variant='outlined'
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                                error={!!errors.name}
                                helperText={errors.name}
                                slotProps={{
                                    input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircle color='primary' />
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                id='email'
                                label='Email'
                                variant='outlined'
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                error={!!errors.email}
                                helperText={errors.email}
                                slotProps={{
                                    input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon color='primary' />
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                id='password'
                                label='Password'
                                type='password'
                                variant='outlined'
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                error={!!errors.password}
                                helperText={errors.password}
                                slotProps={{
                                    input: {
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockIcon color='primary' />
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />
                            <LoadingButton
                                variant='contained'
                                fullWidth
                                sx={{
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    fontSize: '18px',
                                    padding: '12px 16px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                                    mb: 2,
                                }}
                                onClick={onSignin}
                                loading={request.isLoading()}
                            >
                                Create Account
                            </LoadingButton>
                            <Button
                                variant='text'
                                sx={{ textTransform: 'none' }}
                                onClick={() => navigate('/signin')}
                            >
                                Already have an account? Sign In
                            </Button>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    );
};

export default SignUpPage;
