import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Alert, Button, Card, CardContent, Container, Divider, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AuthStatus, useAuth } from './Auth';
import { RequestSnackbar, useRequest } from '../api/Request';
import LoadingPage from '../loading/LoadingPage';
import GoogleIcon from '@mui/icons-material/Google';
import { Email as EmailIcon, Lock as LockIcon } from '@mui/icons-material';
import ArtistLogo from '../assets/ArtistLogo.svg';
import { getConfig } from '../config';

const SignInPage = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<Record<string, string>>({});
    const request = useRequest();
    const config = getConfig();

    if (auth.status === AuthStatus.Loading) {
        return <LoadingPage />;
    }

    if (auth.status === AuthStatus.Authenticated) {
        return <Navigate to='/' />;
    }

    const onSignin = () => {
        const errors: Record<string, string> = {};
        if (email.trim().length === 0) {
            errors.email = 'Email is required';
        }
        if (password.length === 0) {
            errors.password = 'Password is required';
        }

        setErrors(errors);
        if (Object.values(errors).length > 0) {
            return;
        }

        request.onStart();
        auth.signin(email.trim(), password).catch((err) => {
            console.error(err);
            if (
                err.code === 'NotAuthorizedException' ||
                err.code === 'UserNotFoundException'
            ) {
                setErrors({ password: 'Incorrect email or password' });
                request.onFailure({ message: 'Incorrect email or password' });
            } else {
                request.onFailure(err);
            }
        });
    };

    const onGoogleSignIn = () => {
        auth.socialSignin('Google');
    };

    // const onFacebookSignIn = () => {
    //     auth.socialSignin('Facebook');
    // };

    return (
        <Container maxWidth='sm' sx={{ pt: 0, pb: 4, mb: '100px' }}>
            <Card
                elevation={6}
                sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                }}>
                <CardContent sx={{ pt: 4 }}>
                    <Stack justifyContent='center' alignItems='center'>
                        <RequestSnackbar request={request} />
                        <Alert severity='warning'>
                            You must have an account to access certain features in this website.
                        </Alert>
                        <img src={ArtistLogo} alt={`${config.artist.name}'s icon`}
                            style={{
                                width: '200px',
                                height: '200px',
                            }} />
                        <Typography variant='h4' textAlign='center' mb={4}>
                            {config.artist.name}
                        </Typography>
                        <Stack width={{ xs: 1, sm: 0.85 }} spacing={3} alignItems='center'>
                            <Button
                                variant='contained'
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '18px',
                                    padding: '12px 16px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                                }}
                                onClick={onGoogleSignIn}
                                startIcon={<GoogleIcon />}
                            >
                                Sign in with Google
                            </Button>
                            {/* <Button
                        variant='contained'
                        onClick={onFacebookSignIn}
                        startIcon={<FacebookOutlinedIcon />}
                    >
                        Sign in with Facebook
                    </Button> */}
                            <Divider sx={{ width: 1 }}>Or</Divider>
                            <Stack width={0.75} spacing={4} alignItems='center'>
                                <TextField
                                    fullWidth
                                    id='email'
                                    label='Email'
                                    variant='outlined'
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    error={!!errors.email}
                                    helperText={errors.email}
                                    slotProps={{
                                        input: {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailIcon color='primary' />
                                                </InputAdornment>
                                            ),
                                        },
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    id='password'
                                    label='Password'
                                    type='password'
                                    variant='outlined'
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    slotProps={{
                                        input: {
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockIcon color='primary' />
                                                </InputAdornment>
                                            ),
                                        },
                                    }}
                                />
                                <LoadingButton
                                    variant='contained'
                                    fullWidth
                                    sx={{
                                        textTransform: 'none',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        padding: '12px 16px',
                                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                                        mb: 2,
                                    }}
                                    onClick={onSignin}
                                    loading={request.isLoading()}
                                >
                                    Sign in
                                </LoadingButton>
                                <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    sx={{ width: 1 }}
                                >
                                    <Button
                                        variant='text'
                                        sx={{ textTransform: 'none' }}
                                        onClick={() => navigate('/signup')}
                                    >
                                        Sign Up
                                    </Button>
                                    <Button
                                        variant='text'
                                        sx={{ textTransform: 'none', alignSelf: 'end' }}
                                        onClick={() => navigate('/forgot-password')}
                                    >
                                        Forgot password?
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    );
};

export default SignInPage;
