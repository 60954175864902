import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Typography, Stack, TextField, Container, Card, CardContent, InputAdornment } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { AuthStatus, useAuth } from './Auth';
import { RequestSnackbar, useRequest } from '../api/Request';
import LoadingPage from '../loading/LoadingPage';
import { getConfig } from '../config';
import ArtistLogo from '../assets/ArtistLogo.svg';
import { Lock } from '@mui/icons-material';

const VerifyEmailPage = () => {
    const auth = useAuth();

    const navigate = useNavigate();
    const locationState = useLocation().state as any;
    const config = getConfig();
    const username: string = locationState?.username;
    const email: string = locationState?.email;
    const password: string = locationState?.password;

    useEffect(() => {
        if (!username || !email || !password) {
            navigate('/signup', { replace: true });
        }
    }, [username, email, password, navigate]);

    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState<string>();

    const submitRequest = useRequest();
    const codeRequest = useRequest();

    if (auth.status === AuthStatus.Loading) {
        return <LoadingPage />;
    }

    if (auth.status === AuthStatus.Authenticated) {
        return <Navigate to='/' />;
    }

    const onSubmit = () => {
        if (code.length === 0) {
            setCodeError('Verification code is required');
            return;
        }
        setCodeError(undefined);

        submitRequest.onStart();

        auth.confirmSignup(username, code)
            .then(() => auth.signin(email, password))
            .catch((err) => {
                console.dir(err);
                if (err.message) {
                    setCodeError(err.message);
                }
                if (err.code === 'AliasExistsException') {
                    submitRequest.onFailure({
                        message:
                            'An account with this email already exists. ' +
                            'Note that if you previously signed in with Google, ' +
                            'you must continue to use that option.',
                    });
                } else {
                    submitRequest.onFailure(err);
                }
            });
    };

    const onResendCode = () => {
        codeRequest.onStart();

        auth.resendSignupCode(username)
            .then(() => {
                codeRequest.onSuccess('New verification code sent');
            })
            .catch((err) => {
                console.dir(err);
                if (err.message) {
                    codeRequest.onFailure(err.message);
                } else {
                    codeRequest.onFailure(err);
                }
            });
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    };

    return (
        <Container maxWidth='sm' sx={{ pt: 0, pb: 4, mb: '100px' }}>
            <Card
                elevation={6}
                sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                }}>
                <RequestSnackbar request={submitRequest} />
                <RequestSnackbar request={codeRequest} showSuccess />
                <CardContent>
                    <Stack justifyContent='center' alignItems='center'>
                        <img src={ArtistLogo} alt={`${config.artist.name}'s icon`}
                            style={{
                                width: '200px',
                                height: '200px',
                            }} />
                        <Typography variant='h4' textAlign='center' mb={4}>
                            {config.artist.name}
                        </Typography>

                        <Stack width={{ xs: 1, sm: 0.85 }} spacing={4} alignItems='center'>
                            <Typography
                                variant='body1'
                                component='div'
                                gutterBottom
                                textAlign='center'
                            >
                                In order to complete your account creation, please enter the
                                verification code sent to {email}.
                            </Typography>
                            <TextField
                                fullWidth
                                id='code'
                                label='Verification Code'
                                variant='outlined'
                                value={code}
                                onChange={(event) => setCode(event.target.value)}
                                onKeyDown={onKeyDown}
                                error={!!codeError}
                                helperText={codeError}
                                slotProps={{
                                    input: {
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <Lock color='primary' />
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />

                            <LoadingButton
                                variant='contained'
                                loading={submitRequest.isLoading()}
                                fullWidth
                                startIcon={<MarkEmailReadIcon />}
                                sx={{
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    fontSize: '18px',
                                    padding: '12px 16px',
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                                }} 
                                onClick={onSubmit}
                            >
                                Verify Email
                            </LoadingButton>

                            <LoadingButton
                                type='submit'
                                variant='text'
                                sx={{ textTransform: 'none' }}
                                onClick={onResendCode}
                                loading={codeRequest.isLoading()}
                            >
                                Send New Code
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    );
};

export default VerifyEmailPage;
